<template>
    <SmartForm
        ref="sodalizioForm"
        :base_url="base_url"
        :base_path="base_path"
        :form_type="form_type"
        :values_url="values_url"
        :after_store_url="after_store_url"
        :after_update_url="after_update_url"
        :redirect_after_save="redirect_after_save"
        :item_id="item_id"
    >
        <template v-if="hasTitleSlot" v-slot:title>
            <slot name="title"></slot>
        </template>
        <template v-if="hasToolbarSlot" v-slot:toolbar>
            <slot name="toolbar"></slot>
        </template>
        <template v-if="hasFooterSlot" v-slot:footer>
            <slot name="footer"></slot>
        </template>
    </SmartForm>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import SmartForm from "@/view/components/SmartForm.vue";

 export default {
     name: 'SportingClubForm',

     props: ['form_type', 'base_url', 'base_path', 'values_url', 'after_store_url', 'after_update_url', 'redirect_after_save', 'item_id'],

     data() {
         return {
             options_loaded: false,
             originalFields: [],
         }
     },
     computed: {
         hasTitleSlot () {
             return !!this.$slots['title']
         },

         hasToolbarSlot () {
             return !!this.$slots['toolbar']
         },

         hasFooterSlot () {
             return !!this.$slots['footer']
         },
     },

     mounted() {
         this.mountWatches();
     },

     components: {
         SmartForm,
     },

     methods: {
         mountWatches() {
             this.$watch(
                 () => {
                     if (this.options_loaded) {
                         return false;
                     }

                     return this.$refs.sodalizioForm.options_loaded;
                 },
                 (value) => {
                     if (value) {
                         for(let i in this.$refs.sodalizioForm.fields) {
                             this.originalFields.push(JSON.parse(JSON.stringify(this.$refs.sodalizioForm.fields[i])));
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('idduration');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     if (value && value.id) {
                         if (value.id === 1402) {
                             this.setFieldVisible('idtemporarydurationtype', true);
                         }
                         else {
                             this.setFieldVisible('idtemporarydurationtype', false);
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('idstatutetype');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     this.showNotaryFields(value);
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('registersubscriptionflag');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     this.showRegistryFields(value);
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('acsionly');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     this.showAcsiFields(value);
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('epsflag');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     if (value) {
                         this.setFieldVisible('epsdescription', true);
                     }
                     else {
                         this.setFieldVisible('epsdescription', false);
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('fsnflag');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     if (value) {
                         this.setFieldVisible('fsndescription', true);
                     }
                     else {
                         this.setFieldVisible('fsndescription', false);
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('dsaflag');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     if (value) {
                         this.setFieldVisible('dsadescription', true);
                     }
                     else {
                         this.setFieldVisible('dsadescription', false);
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('otherflag');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     if (value) {
                         this.setFieldVisible('otherdescription', true);
                     }
                     else {
                         this.setFieldVisible('otherdescription', false);
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('idregion');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldProvstate = null;
                     let fieldProvstateValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldProvstate = this.findField('idprovstate');
                             fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('provstates', 'idregion', value.id, 'idprovstate',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    provstatecode: 'Seleziona una provincia',
                                                });
                                            },
                                            function(options) {
                                                fieldProvstate = vm.findField('idprovstate');

                                                if (fieldProvstate) {
                                                    vm.$set(fieldProvstate, 'filter_options', options);

                                                    if (typeof fieldProvstate.value !== "undefined") {
                                                        if (fieldProvstateValue !== null) {
                                                            fieldProvstate.value = fieldProvstateValue;
                                                        }
                                                        else {
                                                            fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('idprovstate');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldCity = null;
                     let fieldCityValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldCity = this.findField('idcity');
                             fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('cities', 'idprovstate', value.id, 'idcity',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    cityname: 'Seleziona una città',
                                                });
                                            },
                                            function (options) {
                                                fieldCity = vm.findField('idcity');

                                                if (fieldCity) {
                                                    vm.$set(fieldCity, 'filter_options', options);

                                                    if (typeof fieldCity.value !== "undefined") {
                                                        if (fieldCityValue !== null) {
                                                            fieldCity.value = fieldCityValue;
                                                        }
                                                        else {
                                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('postalidregion');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldProvstate = null;
                     let fieldProvstateValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldProvstate = this.findField('postalidprovstate');
                             fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value ? fieldProvstate.value : {}));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('provstates', 'idregion', value.id, 'postalidprovstate',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    provstatecode: 'Seleziona una provincia',
                                                });
                                            },
                                            function(options) {
                                                fieldProvstate = vm.findField('postalidprovstate');

                                                if (fieldProvstate) {
                                                    vm.$set(fieldProvstate, 'filter_options', options);

                                                    if (typeof fieldProvstate.value !== "undefined") {
                                                        if (fieldProvstateValue !== null) {
                                                            fieldProvstate.value = fieldProvstateValue;
                                                        }
                                                        else {
                                                            fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('postalidprovstate');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldCity = null;
                     let fieldCityValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldCity = this.findField('postalidcity');
                             fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value ? fieldCity.value : {}));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('cities', 'idprovstate', value.id, 'postalidcity',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    cityname: 'Seleziona una città',
                                                });
                                            },
                                            function (options) {
                                                fieldCity = vm.findField('postalidcity');

                                                if (fieldCity) {
                                                    vm.$set(fieldCity, 'filter_options', options);

                                                    if (typeof fieldCity.value !== "undefined") {
                                                        if (fieldCityValue !== null) {
                                                            fieldCity.value = fieldCityValue;
                                                        }
                                                        else {
                                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('taxidregion');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldProvstate = null;
                     let fieldProvstateValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldProvstate = this.findField('taxidprovstate');
                             fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value ? fieldProvstate.value : {}));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('provstates', 'idregion', value.id, 'taxidprovstate',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    provstatecode: 'Seleziona una provincia',
                                                });
                                            },
                                            function(options) {
                                                fieldProvstate = vm.findField('taxidprovstate');

                                                if (fieldProvstate) {
                                                    vm.$set(fieldProvstate, 'filter_options', options);

                                                    if (typeof fieldProvstate.value !== "undefined") {
                                                        if (fieldProvstateValue !== null) {
                                                            fieldProvstate.value = fieldProvstateValue;
                                                        }
                                                        else {
                                                            fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let fields = this.$refs.sodalizioForm.fields;

                     if (fields.length)
                     {
                         if (this.$refs.sodalizioForm.form_type === 'edit')
                         {
                             if (this.$refs.sodalizioForm.values_loaded === null || this.$refs.sodalizioForm.values_loaded === false) {
                                 return false;
                             }
                         }

                         let field = this.findField('taxidprovstate');
                         let value = this.getFieldValue(field);

                         if (typeof value !== "undefined" && value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 },
                 (newValue, oldValue) => {
                     let doUpdate;
                     let fieldCity = null;
                     let fieldCityValue = null;

                     if (newValue === false) {
                         doUpdate = false;
                     }
                     else {
                         doUpdate = true;
                         if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                         if (oldValue === false) {
                             fieldCity = this.findField('taxidcity');
                             fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value ? fieldCity.value : {}));
                         }
                     }

                     if (doUpdate) {
                         let vm = this;
                         let value = { id: newValue };
                         if (value) {
                             vm.loadOptions('cities', 'idprovstate', value.id, 'taxidcity',
                                            function(options) {
                                                options.unshift({
                                                    id: 0,
                                                    cityname: 'Seleziona una città',
                                                });
                                            },
                                            function (options) {
                                                fieldCity = vm.findField('taxidcity');

                                                if (fieldCity) {
                                                    vm.$set(fieldCity, 'filter_options', options);

                                                    if (typeof fieldCity.value !== "undefined") {
                                                        if (fieldCityValue !== null) {
                                                            fieldCity.value = fieldCityValue;
                                                        }
                                                        else {
                                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                        }
                                                    }
                                                }
                                            }
                             );
                         }
                     }
                 }
             );

             if (this.$refs.sodalizioForm.form_type === 'new') {
                 this.$watch(
                     () => {
                         let field = this.findField('facilities');

                         if (field) {
                             field = typeof field !== "undefined" ? field : null;
                         }

                         if ((typeof field !== "undefined") && (typeof field.items !== "undefined") && field.items.length) {
                             let items = [];

                             for (let i in field.items) {
                                 items.push({
                                     repeater_unique_id: field.items[i].repeater_unique_id,
                                     id_region: (((typeof field.items[i].fields[1].value !== "undefined") && (typeof field.items[i].fields[1].value.id !== "undefined")) ? field.items[i].fields[1].value.id : null),
                                     id_provstate: (((typeof field.items[i].fields[2].value !== "undefined") && (typeof field.items[i].fields[2].value.id !== "undefined")) ? field.items[i].fields[2].value.id : null),
                                     provstateField: field.items[i].fields[2],
                                     cityField: field.items[i].fields[3],
                                 });
                             }

                             return items;
                         }

                         return [];
                     },
                     (newItems, oldItems) => {
                         for (let i in newItems) {
                             let doUpdateProvState = false;
                             let doUpdateCity = false;

                             let item = oldItems.find(function (item) {
                                 return item.repeater_unique_id === newItems[i].repeater_unique_id;
                             });

                             if ((typeof item === "undefined") && (typeof newItems[i].id_region !== "undefined")) {
                                 doUpdateProvState = true;
                             }
                             else if (item.id_region !== newItems[i].id_region) {
                                 doUpdateProvState = true;
                             }

                             if (doUpdateProvState) {
                                 let idRegion = newItems[i].id_region;
                                 let provstateField = newItems[i].provstateField;
                                 let cityField = newItems[i].cityField;

                                 if (idRegion) {
                                     this.loadOptions('provstates', 'idregion', idRegion, 'idprovstate',
                                                      function(options) {
                                                          options.unshift({
                                                              id: 0,
                                                              provstatecode: 'Seleziona una provincia',
                                                          });
                                                      },
                                                      function (options) {
                                                          provstateField.filter_options = options;
                                                          if (typeof provstateField.value !== "undefined") provstateField.value = JSON.parse(JSON.stringify(options[0]));

                                                          if (typeof cityField.value !== "undefined" && cityField.filter_options !== "undefined" && cityField.filter_options.length) cityField.value = JSON.parse(JSON.stringify(cityField.filter_options[0]));
                                                      }
                                     );
                                 }
                             }
                             else {
                                 if (item.id_provstate !== newItems[i].id_provstate) doUpdateCity = true;

                                 if (doUpdateCity) {
                                     let idProvstate = newItems[i].id_provstate;
                                     let cityField = newItems[i].cityField;

                                     if (idProvstate) {
                                         this.loadOptions('cities', 'idprovstate', idProvstate, 'idprovstate',
                                                          function(options) {
                                                              options.unshift({
                                                                  id: 0,
                                                                  cityname: 'Seleziona una città',
                                                              });
                                                          },
                                                          function (options) {
                                                              cityField.filter_options = options;
                                                              if (typeof cityField.value !== "undefined") cityField.value = JSON.parse(JSON.stringify(options[0]));
                                                          }
                                         );
                                     }
                                 }
                             }
                         }
                     }
                 );
             }

             if (this.$refs.sodalizioForm.form_type === 'new') {
                 this.$watch(
                     () => {
                         let field = this.findField('members');

                         if (field) {
                             field = typeof field !== "undefined" ? field : null;
                         }

                         if ((typeof field !== "undefined") && (typeof field.items !== "undefined") && field.items.length) {
                             let items = [];

                             for (let i in field.items) {
                                 items.push({
                                     repeater_unique_id: field.items[i].repeater_unique_id,

                                     id_roletype: (((typeof field.items[i].fields[0].value !== "undefined") && (typeof field.items[i].fields[0].value.id !== "undefined")) ? field.items[i].fields[0].value.id : null),
                                     id_region: (((typeof field.items[i].fields[4].value !== "undefined") && (typeof field.items[i].fields[4].value.id !== "undefined")) ? field.items[i].fields[4].value.id : null),
                                     id_provstate: (((typeof field.items[i].fields[5].value !== "undefined") && (typeof field.items[i].fields[5].value.id !== "undefined")) ? field.items[i].fields[5].value.id : null),

                                     regionField: field.items[i].fields[4],
                                     provstateField: field.items[i].fields[5],
                                     cityField: field.items[i].fields[6],
                                     streetaddressField: field.items[i].fields[7],
                                     streetnumberField: field.items[i].fields[8],
                                     zipcodeField: field.items[i].fields[9],
                                     emailField: field.items[i].fields[10],
                                     phonenumberField: field.items[i].fields[11],
                                     iddocumenttypeField: field.items[i].fields[12],
                                     documentnumberField: field.items[i].fields[13],
                                     electiondateField: field.items[i].fields[15],
                                 });
                             }

                             return items;
                         }

                         return [];
                     },
                     (newItems, oldItems) => {
                         for (let i in newItems) {
                             let doUpdateRole = false;
                             let doUpdateProvState = false;
                             let doUpdateCity = false;

                             let item = oldItems.find(function (item) {
                                 return item.repeater_unique_id === newItems[i].repeater_unique_id;
                             });

                             if ((typeof item === "undefined") && (typeof newItems[i].id_roletype !== "undefined")) {
                                 doUpdateRole = true;
                             }
                             else if (item.id_roletype !== newItems[i].id_roletype) {
                                 doUpdateRole = true;
                             }

                             if ((typeof item === "undefined") && (typeof newItems[i].id_region !== "undefined")) {
                                 doUpdateProvState = true;
                             }
                             else if (item.id_region !== newItems[i].id_region) {
                                 doUpdateProvState = true;
                             }

                             if (doUpdateRole) {
                                 let idRoleType = newItems[i].id_roletype;

                                 let regionField;
                                 let provstateField;
                                 let cityField;
                                 let streetaddressField;
                                 let streetnumberField;
                                 let zipcodeField;
                                 let emailField;
                                 let phonenumberField;
                                 let iddocumenttypeField;
                                 let documentnumberField;
                                 let electiondateField;

                                 switch (idRoleType) {
                                     case 2101: // Presidente
                                     case 2115: // Amministratore unico
                                         regionField = newItems[i].regionField;
                                         provstateField = newItems[i].provstateField;
                                         cityField = newItems[i].cityField;
                                         streetaddressField = newItems[i].streetaddressField;
                                         streetnumberField = newItems[i].streetnumberField;
                                         zipcodeField = newItems[i].zipcodeField;
                                         emailField = newItems[i].emailField;
                                         phonenumberField = newItems[i].phonenumberField;
                                         iddocumenttypeField = newItems[i].iddocumenttypeField;
                                         documentnumberField = newItems[i].documentnumberField;
                                         electiondateField = newItems[i].electiondateField;

                                         regionField.is_visible_for_store = true;
                                         provstateField.is_visible_for_store = true;
                                         cityField.is_visible_for_store = true;
                                         streetaddressField.is_visible_for_store = true;
                                         streetnumberField.is_visible_for_store = true;
                                         zipcodeField.is_visible_for_store = true;
                                         phonenumberField.is_visible_for_store = true;
                                         iddocumenttypeField.is_visible_for_store = true;
                                         documentnumberField.is_visible_for_store = true;

                                         regionField.is_visible_for_update = true;
                                         provstateField.is_visible_for_update = true;
                                         cityField.is_visible_for_update = true;
                                         streetaddressField.is_visible_for_update = true;
                                         streetnumberField.is_visible_for_update = true;
                                         zipcodeField.is_visible_for_update = true;
                                         phonenumberField.is_visible_for_update = true;
                                         iddocumenttypeField.is_visible_for_update = true;
                                         documentnumberField.is_visible_for_update = true;

                                         emailField.nullable = false;

                                         electiondateField.nullable = false;
                                         break;

                                     case 2102: // Vicepresidente
                                     case 2103: // Segretario
                                     case 2107: // Tesoriere
                                     case 2108: // Revisore dei conti
                                     case 2104: // Consigliere
                                         regionField = newItems[i].regionField;
                                         provstateField = newItems[i].provstateField;
                                         cityField = newItems[i].cityField;
                                         streetaddressField = newItems[i].streetaddressField;
                                         streetnumberField = newItems[i].streetnumberField;
                                         zipcodeField = newItems[i].zipcodeField;
                                         emailField = newItems[i].emailField;
                                         phonenumberField = newItems[i].phonenumberField;
                                         iddocumenttypeField = newItems[i].iddocumenttypeField;
                                         documentnumberField = newItems[i].documentnumberField;
                                         electiondateField = newItems[i].electiondateField;

                                         regionField.is_visible_for_store = false;
                                         provstateField.is_visible_for_store = false;
                                         cityField.is_visible_for_store = false;
                                         streetaddressField.is_visible_for_store = false;
                                         streetnumberField.is_visible_for_store = false;
                                         zipcodeField.is_visible_for_store = false;
                                         phonenumberField.is_visible_for_store = false;
                                         iddocumenttypeField.is_visible_for_store = false;
                                         documentnumberField.is_visible_for_store = false;

                                         regionField.is_visible_for_update = false;
                                         provstateField.is_visible_for_update = false;
                                         cityField.is_visible_for_update = false;
                                         streetaddressField.is_visible_for_update = false;
                                         streetnumberField.is_visible_for_update = false;
                                         zipcodeField.is_visible_for_update = false;
                                         phonenumberField.is_visible_for_update = false;
                                         iddocumenttypeField.is_visible_for_update = false;
                                         documentnumberField.is_visible_for_update = false;

                                         emailField.nullable = true;

                                         let field = this.findField('idsodalitytype');

                                         let value = this.getFieldValue(field);

                                         if (value && value.id) {
                                             switch (value.id) {
                                                 case 1801: // Associazione o circolo non sportivo
                                                 case 1802: // Associazione sportiva base (BAS)
                                                 case 1807: // Associazione di Promozione Sociale (APS)
                                                 case 1808: // Organizzazione di Volontariato (ODV)
                                                     electiondateField.nullable = true;
                                                     break;

                                                 case 1803: // Associazione sportiva con personalità giuridica (ASD)
                                                 case 1805: // Associazione sportiva senza personalità giuridica (ASD)
                                                 case 1806: // Società sportiva con personalità giuridica (SSD)
                                                 case 1809: // Associazione Sportiva Dilettantistica - Associazione di Promozione Sociale (ASD - APS)
                                                     electiondateField.nullable = false;
                                                     break;
                                             }
                                         }
                                         break;
                                 }
                             }

                             if (doUpdateProvState) {
                                 let idRegion = newItems[i].id_region;
                                 let provstateField = newItems[i].provstateField;
                                 let cityField = newItems[i].cityField;

                                 if (idRegion) {
                                     this.loadOptions('provstates', 'idregion', idRegion, 'idprovstate',
                                                      function(options) {
                                                          options.unshift({
                                                              id: 0,
                                                              provstatecode: 'Seleziona una provincia',
                                                          });
                                                      },
                                                      function (options) {
                                                          provstateField.filter_options = options;
                                                          if (typeof provstateField.value !== "undefined") provstateField.value = JSON.parse(JSON.stringify(options[0]));

                                                          if (typeof cityField.value !== "undefined" && cityField.filter_options !== "undefined" && cityField.filter_options.length) cityField.value = JSON.parse(JSON.stringify(cityField.filter_options[0]));
                                                      }
                                     );
                                 }
                             }
                             else {
                                 if (item.id_provstate !== newItems[i].id_provstate) doUpdateCity = true;

                                 if (doUpdateCity) {
                                     let idProvstate = newItems[i].id_provstate;
                                     let cityField = newItems[i].cityField;

                                     if (idProvstate) {
                                         this.loadOptions('cities', 'idprovstate', idProvstate, 'idprovstate',
                                                          function(options) {
                                                              options.unshift({
                                                                  id: 0,
                                                                  cityname: 'Seleziona una città',
                                                              });
                                                          },
                                                          function (options) {
                                                              cityField.filter_options = options;
                                                              if (typeof cityField.value !== "undefined") cityField.value = JSON.parse(JSON.stringify(options[0]));
                                                          }
                                         );
                                     }
                                 }
                             }
                         }
                     }
             );
             }

             this.$watch(
                 () => {
                     let field = this.findField('idsodalitytype');

                     let value = this.getFieldValue(field);
                     return ((value && value.id) ? value.id : null);
                 },
                 (idsodalitytype) => {
                     let nullable;
                     switch (idsodalitytype) {
                         case 1801: // Associazione o circolo non sportivo
                         case 1802: // Associazione sportiva base (BAS)
                         case 1807: // Associazione di Promozione Sociale (APS)
                         case 1808: // Organizzazione di Volontariato (ODV)
                             nullable = true;
                             break;

                         case 1803: // Associazione sportiva con personalità giuridica (ASD)
                         case 1805: // Associazione sportiva senza personalità giuridica (ASD)
                         case 1806: // Società sportiva con personalità giuridica (SSD)
                         case 1809: // Associazione Sportiva Dilettantistica - Associazione di Promozione Sociale (ASD - APS)
                             nullable = false;
                             break;
                     }

                     let members = [];
                     switch (idsodalitytype) {
                         case 1801: // Associazione o circolo non sportivo
                         case 1802: // Associazione sportiva base (BAS)
                         case 1807: // Associazione di Promozione Sociale (APS)
                         case 1808: // Organizzazione di Volontariato (ODV)
                         case 1803: // Associazione sportiva con personalità giuridica (ASD)
                         case 1805: // Associazione sportiva senza personalità giuridica (ASD)
                         case 1809: // Associazione Sportiva Dilettantistica - Associazione di Promozione Sociale (ASD - APS)
                             members = [2101, 2102];
                             break;
                         case 1806: // Società sportiva con personalità giuridica (SSD)
                             members = [2115];
                             break;
                     }

                     let field = this.findField('members');

                     if (field) {
                         field = typeof field !== "undefined" ? field : null;
                     }

                     if ((typeof field !== "undefined") && (typeof field.items !== "undefined") && field.items.length) {
                         for (let i in field.items) {
                             let idroletype = (((typeof field.items[i].fields[0].value !== "undefined") && (typeof field.items[i].fields[0].value.id !== "undefined")) ? field.items[i].fields[0].value.id : null);

                             let electiondateField = field.items[i].fields[15];

                             switch(idroletype) {
                                 case 2101: // Presidente
                                 case 2115: // Amministratore unico
                                     electiondateField.nullable = false;
                                     break;

                                 case 2102: // Vicepresidente
                                 case 2103: // Segretario
                                 case 2107: // Tesoriere
                                 case 2108: // Revisore dei conti
                                 case 2104: // Consigliere
                                     electiondateField.nullable = nullable;
                                     break;
                             }
                         }
                     }

                     if (typeof field !== "undefined") {
                         this.setFieldAttribute('members', 'items', []);

                         for (let i in members) {
                             let fields = JSON.parse(JSON.stringify(field.fields));

                             field.items.push({
                                 repeater_unique_id: this.generateRandomId(),
                                 repeater_removable: false,
                                 fields: fields,
                             });

                             let option = field.items[field.items.length - 1].fields[0].filter_options.find(function(item) {
                                 return item.id === members[i];
                             });

                             this.$set(field.items[field.items.length - 1].fields[0], 'value', JSON.parse(JSON.stringify(option)));
                         }
                     }
                 }
             );

             this.$watch(
                 () => {
                     let field = this.findField('idsodalitytype');

                     return this.getFieldValue(field);
                 },
                 (value) => {
                     let field;

                     if (value) {
                        let action = null;
                        let coniaffiliationflagvalue = null;

                         switch(value.id) {
                             case 1801: // Associazione o circolo non sportivo
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);
                                 this.setFieldVisible('idtemporarydurationtype', false);

                                coniaffiliationflagvalue = 0;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }
                                 this.setFieldVisible('coniaffiliationflag', false);
                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 //this.setFieldVisible('cyclingflag', false);
                                 this.setFieldVisible('cip2activities', false);
                                 this.setFieldVisible('coni2activities', false);
                                 this.setFieldVisible('socialstatuteupdatedate', false);
                                 this.setFieldVisible('idstatutetype', false);
                                 this.setFieldVisible('idcompanytype', false);
                                 this.setFieldVisible('idgstype', false);

                                this.setFieldVisible('statuteactivities', false);
                                this.setFieldVisible('idruntstype', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldVisible('idareaofinterest', false);

                                 this.hidePostalAddresses();
                                 this.hideTaxAddresses();
                                 break;

                             case 1802: // Associazione sportiva di base (BAS)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);
                                 this.setFieldVisible('idtemporarydurationtype', false);

                                 coniaffiliationflagvalue = 0;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }
                                 this.setFieldVisible('coniaffiliationflag', false);
                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 //this.setFieldVisible('cyclingflag', false);
                                 this.setFieldAttribute('taxcode', 'nullable', true);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idstatutetype', false);
                                 this.setFieldVisible('idcompanytype', false);
                                 this.setFieldVisible('idgstype', false);

                                this.setFieldVisible('statuteactivities', false);
                                this.setFieldVisible('idruntstype', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.hidePostalAddresses();
                                 this.hideTaxAddresses();
                                 break;

                             case 1805: // Associazione sportiva senza personalità giuridica (ASD)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 4, 5, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 3, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);

                                 this.setFieldVisible('coniaffiliationflag', true);
                                 this.setFieldAttribute('coniaffiliationflag', 'type', 'hidden');
                                 this.setFieldAttribute('coniaffiliationflag', 'format', 'html');
                                 this.setFieldAttribute('coniaffiliationflag', 'message', 'Le ASD/SSD/ASD-APS che richiedono l\'affiliazione ad ACSI verranno inviate automaticamente al Registro CONI, essendo quest\'ultimo l\'unico organismo certificatore dell\'effettiva attività sportiva svolta dalle associazioni e società sportive dilettantistiche (ex art. 7, comma 1, del DL 136/2004).<br><br>Per le ASD/SSD/ASD-APS che svolgono solo o almeno un\'attività paralimpica, l\'iscrizione alla sezione CIP del Registro 2.0, avviene inserendo e/o aggiungendo nella domanda di affiliazione almeno una disciplina CIP selezionandola da apposito menu a tendina.');

                                 this.setFieldAttribute('coniaffiliationflag', 'options', []);

                                 coniaffiliationflagvalue = 1;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }

                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);

                                this.setFieldVisible('statuteactivities', false);
                                this.setFieldVisible('idruntstype', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldAttribute('activities', 'nullable', false);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', false);

                                 this.setFieldAttribute('officeregistration', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdetails', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdate', 'nullable', false);
                                 this.setFieldAttribute('boardvariationdate', 'nullable', false);

                                 this.showPostalAddresses();
                                 this.showTaxAddresses();
                                 break;

                             case 1803: // Associazione sportiva con personalità giuridica (ASD)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 4, 5, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 3, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);

                                 this.setFieldVisible('coniaffiliationflag', true);
                                 this.setFieldAttribute('coniaffiliationflag', 'type', 'hidden');
                                 this.setFieldAttribute('coniaffiliationflag', 'format', 'html');
                                 this.setFieldAttribute('coniaffiliationflag', 'message', 'Le ASD/SSD/ASD-APS che richiedono l\'affiliazione ad ACSI verranno inviate automaticamente al Registro CONI, essendo quest\'ultimo l\'unico organismo certificatore dell\'effettiva attività sportiva svolta dalle associazioni e società sportive dilettantistiche (ex art. 7, comma 1, del DL 136/2004).<br><br>Per le ASD/SSD/ASD-APS che svolgono solo o almeno un\'attività paralimpica, l\'iscrizione alla sezione CIP del Registro 2.0, avviene inserendo e/o aggiungendo nella domanda di affiliazione almeno una disciplina CIP selezionandola da apposito menu a tendina.');

                                 this.setFieldAttribute('coniaffiliationflag', 'options', []);

                                 coniaffiliationflagvalue = 1;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }

                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);

                                this.setFieldVisible('statuteactivities', false);
                                this.setFieldVisible('idruntstype', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldAttribute('activities', 'nullable', false);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', false);

                                 this.setFieldAttribute('officeregistration', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdetails', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdate', 'nullable', false);
                                 this.setFieldAttribute('boardvariationdate', 'nullable', false);

                                 this.showPostalAddresses();
                                 this.showTaxAddresses();
                                 break;

                             case 1806: // Società sportiva con personalità giuridica (SSD)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 4, 5, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 3, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);

                                 this.setFieldVisible('coniaffiliationflag', true);
                                 this.setFieldAttribute('coniaffiliationflag', 'type', 'hidden');
                                 this.setFieldAttribute('coniaffiliationflag', 'format', 'html');
                                 this.setFieldAttribute('coniaffiliationflag', 'message', 'Le ASD/SSD/ASD-APS che richiedono l\'affiliazione ad ACSI verranno inviate automaticamente al Registro CONI, essendo quest\'ultimo l\'unico organismo certificatore dell\'effettiva attività sportiva svolta dalle associazioni e società sportive dilettantistiche (ex art. 7, comma 1, del DL 136/2004).<br><br>Per le ASD/SSD/ASD-APS che svolgono solo o almeno un\'attività paralimpica, l\'iscrizione alla sezione CIP del Registro 2.0, avviene inserendo e/o aggiungendo nella domanda di affiliazione almeno una disciplina CIP selezionandola da apposito menu a tendina.');

                                 this.setFieldAttribute('coniaffiliationflag', 'options', []);

                                 coniaffiliationflagvalue = 1;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }

                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);

                                this.setFieldVisible('statuteactivities', false);
                                this.setFieldVisible('idruntstype', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldAttribute('activities', 'nullable', false);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', false);

                                 this.setFieldAttribute('officeregistration', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdetails', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdate', 'nullable', false);
                                 this.setFieldAttribute('boardvariationdate', 'nullable', false);

                                 this.showPostalAddresses();
                                 this.showTaxAddresses();
                                 break;

                             case 1804: // Società o cooperativa
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 4, 5, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 3, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);

                                 this.setFieldVisible('coniaffiliationflag', true);
                                 this.setFieldAttribute('coniaffiliationflag', 'type', 'hidden');
                                 this.setFieldAttribute('coniaffiliationflag', 'format', 'html');
                                 this.setFieldAttribute('coniaffiliationflag', 'message', 'Le ASD/SSD/ASD-APS che richiedono l\'affiliazione ad ACSI verranno inviate automaticamente al Registro CONI, essendo quest\'ultimo l\'unico organismo certificatore dell\'effettiva attività sportiva svolta dalle associazioni e società sportive dilettantistiche (ex art. 7, comma 1, del DL 136/2004).<br><br>Per le ASD/SSD/ASD-APS che svolgono solo o almeno un\'attività paralimpica, l\'iscrizione alla sezione CIP del Registro 2.0, avviene inserendo e/o aggiungendo nella domanda di affiliazione almeno una disciplina CIP selezionandola da apposito menu a tendina.');

                                 this.setFieldAttribute('coniaffiliationflag', 'options', []);

                                 coniaffiliationflagvalue = 1;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }

                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldAttribute('activities', 'nullable', false);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', false);

                                 this.showPostalAddresses();
                                 this.showTaxAddresses();
                                 break;

                             case 1807: // Associazione di Promozione Sociale (APS)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);
                                 this.setFieldVisible('registersubscriptionflag', true);

                                this.setFieldVisible('numemployees', true);
                                this.setFieldVisible('numvolunteers', true);

                                 this.checkRegistry();

                                 coniaffiliationflagvalue = 0;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }
                                 this.setFieldVisible('coniaffiliationflag', false);
                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldAttribute('activities', 'nullable', true);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', true);

                                 this.setFieldVisible('cip2activities', true);
                                 this.setFieldVisible('activities', true);
                                 this.setFieldVisible('coni2activities', true);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldVisible('idcompanytype', false);
                                 this.setFieldVisible('idgstype', false);
                                 this.setFieldVisible('idduration', false);

                                this.setFieldVisible('statuteactivities', true);
                                this.setFieldVisible('idruntstype', true);

                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);
                                 this.setFieldVisible('idstatutetype', false);

                                 this.hidePostalAddresses();
                                 this.hideTaxAddresses();
                                 break;

                             case 1808: // Organizzazione di Volontariato (ODV)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);
                                 this.setFieldVisible('registersubscriptionflag', true);

                                this.setFieldVisible('numemployees', true);
                                this.setFieldVisible('numvolunteers', true);

                                 this.checkRegistry();

                                 coniaffiliationflagvalue = 0;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }
                                 this.setFieldVisible('coniaffiliationflag', false);
                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('cip2activities', false);
                                 this.setFieldVisible('coni2activities', false);
                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldVisible('idcompanytype', false);
                                 this.setFieldVisible('idgstype', false);
                                 this.setFieldVisible('idduration', false);

                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);
                                 this.setFieldVisible('idstatutetype', false);

                                 this.hidePostalAddresses();
                                 this.hideTaxAddresses();
                                 break;

                             case 1809: // Associazione Sportiva Dilettantistica - Associazione di Promozione Sociale (ASD - APS)
                                 this.resetFields();

                                 if (this.$refs.sodalizioForm.form_type === 'new') {
                                     action = 'new';

                                     this.showSteps([0, 1, 2, 3, 4, 5, 6]);
                                 }

                                 if (this.$refs.sodalizioForm.form_type === 'edit') {
                                     action = 'edit';

                                     this.showSteps([0, 1, 2, 3, 4]);
                                 }
                                 // 0 - comitato
                                 // 1 - dati
                                 // 2 - identificazione
                                 // 3 - consiglio
                                 // 4 - inviabilita
                                 // 5 - impianti
                                 // 6 - privacy

                                 this.hideNotaryFields();
                                 this.checkStatute();
                                 this.checkAcsiOnly();

                                 this.setFieldVisible('foodflag', true);

                                 this.setFieldVisible('registersubscriptionflag', true);

                                this.setFieldVisible('numemployees', true);
                                this.setFieldVisible('numvolunteers', true);

                                 this.checkRegistry();

                                 this.setFieldVisible('coniaffiliationflag', true);
                                 this.setFieldAttribute('coniaffiliationflag', 'type', 'hidden');
                                 this.setFieldAttribute('coniaffiliationflag', 'format', 'html');
                                 this.setFieldAttribute('coniaffiliationflag', 'message', 'Le ASD/SSD/ASD-APS che richiedono l\'affiliazione ad ACSI verranno inviate automaticamente al Registro CONI, essendo quest\'ultimo l\'unico organismo certificatore dell\'effettiva attività sportiva svolta dalle associazioni e società sportive dilettantistiche (ex art. 7, comma 1, del DL 136/2004).<br><br>Per le ASD/SSD/ASD-APS che svolgono solo o almeno un\'attività paralimpica, l\'iscrizione alla sezione CIP del Registro 2.0, avviene inserendo e/o aggiungendo nella domanda di affiliazione almeno una disciplina CIP selezionandola da apposito menu a tendina.');

                                 this.setFieldAttribute('coniaffiliationflag', 'options', []);

                                 coniaffiliationflagvalue = 1;
                                 // if (action === 'edit') {
                                 //     let coniaffiliationflagfield = this.findField('coniaffiliationflag');
                                 //     coniaffiliationflagvalue = this.getFieldValue(coniaffiliationflagfield);
                                 // }

                                 this.setFieldAttribute('coniaffiliationflag', 'value', coniaffiliationflagvalue);

                                 this.setFieldVisible('idtemporarydurationtype', false);
                                 this.setFieldAttribute('socialstatuteupdatedate', 'nullable', true);
                                 this.setFieldVisible('idareaofinterest', false);

                                this.setFieldVisible('statuteactivities', true);
                                this.setFieldVisible('idruntstype', true);

                                 field = this.findField('idduration');
                                 value = this.getFieldValue(field);

                                 if (value && value.id) {
                                     if (value.id === 1402) {
                                         this.setFieldVisible('idtemporarydurationtype', true);
                                     }
                                     else {
                                         this.setFieldVisible('idtemporarydurationtype', false);
                                     }
                                 }

                                 this.setFieldAttribute('activities', 'nullable', false);
                                 this.setFieldAttribute('cip2activities', 'nullable', true);
                                 this.setFieldAttribute('coni2activities', 'nullable', false);

                                 this.setFieldAttribute('officeregistration', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdetails', 'nullable', false);
                                 this.setFieldAttribute('officeregistrationdate', 'nullable', false);
                                 this.setFieldAttribute('boardvariationdate', 'nullable', false);

                                 this.showPostalAddresses();
                                 this.showTaxAddresses();
                                 break;
                         }
                     }
                 }
         );
         },
         generateRandomId() {
             return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                 let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

                 return v.toString(16);
             });
         },

         findField(name) {
             return this.$refs.sodalizioForm.fields.find(item => item.name === name);
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(name, visible) {
             let fieldIndex = this.$refs.sodalizioForm.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(this.$refs.sodalizioForm.fields[fieldIndex], 'is_visible_for_store', visible);
                 this.$set(this.$refs.sodalizioForm.fields[fieldIndex], 'is_visible_for_update', visible);
             }
         },

         setFieldAttribute(name, attribute, value) {
             let fieldIndex = this.$refs.sodalizioForm.fields.findIndex(item => item.name === name);
             if (fieldIndex && typeof this.$refs.sodalizioForm.fields[fieldIndex] !== "undefined") {
                 this.$set(this.$refs.sodalizioForm.fields[fieldIndex], attribute, value);
             }
         },

         showSteps(visibleSteps) {
             this.$set(this.$refs.sodalizioForm, 'availableSteps', visibleSteps);

             for(let i in this.$refs.sodalizioForm.steps) {
                 this.$set(this.$refs.sodalizioForm.steps[i], 'visible', false);
             }

             if (this.$refs.sodalizioForm.steps.length) {
                 let count = 0;
                 for(let i in visibleSteps) {
                     count++;
                     this.$set(this.$refs.sodalizioForm.steps[visibleSteps[i]], 'visible', true);
                     this.$set(this.$refs.sodalizioForm.steps[visibleSteps[i]], 'step', count.toString());
                 }
             }
         },

         hideNotaryFields() {
             this.setFieldVisible('notary', false);
             this.setFieldVisible('notarydistrict', false);
             this.setFieldVisible('notaryrepnum', false);
             this.setFieldVisible('giuridicpersonregisternumber', false);
             this.setFieldVisible('prefecture', false);
         },

         showNotaryFields(value) {
             let showNotaryFields = false;

             if (value && value.id) {
                 if (value.id === 1702 || value.id === 1704) {
                     showNotaryFields = true;
                 }
             }

             if (showNotaryFields) {
                 if (value && value.id === 1702) {
                     this.setFieldAttribute('giuridicpersonregisternumber', 'nullable', true);
                     this.setFieldAttribute('prefecture', 'nullable', true);
                 }
                 else {
                     this.setFieldAttribute('giuridicpersonregisternumber', 'nullable', false);
                     this.setFieldAttribute('prefecture', 'nullable', false);
                 }

                 this.setFieldVisible('notary', true);
                 this.setFieldVisible('notarydistrict', true);
                 this.setFieldVisible('notaryrepnum', true);
                 this.setFieldVisible('giuridicpersonregisternumber', true);
                 this.setFieldVisible('prefecture', true);
             }
             else {
                 this.setFieldVisible('notary', false);
                 this.setFieldVisible('notarydistrict', false);
                 this.setFieldVisible('notaryrepnum', false);
                 this.setFieldVisible('giuridicpersonregisternumber', false);
                 this.setFieldVisible('prefecture', false);
             }
         },

         checkStatute() {
             let field = this.findField('idstatutetype');

             this.showNotaryFields(field.value);
         },

         showRegistryFields(value) {
             let show_nationalregistryflagfield = false;
             let show_regionalregistryflagfield = false;
             let show_registrynumber = false;
             let show_registrydate = false;

             let field = this.findField('idsodalitytype');
             if (field) {
                 if (value && field.value && field.value.id && [1807, 1808, 1809].includes(field.value.id)) {
                     show_registrynumber = true;
                     show_registrydate = true;
                 }
             }

             if (show_nationalregistryflagfield) {
                 this.setFieldVisible('nationalregistryflag', true);
             }
             else {
                 this.setFieldVisible('nationalregistryflag', false);
             }

             if (show_regionalregistryflagfield) {
                 this.setFieldVisible('regionalregistryflag', true);
             }
             else {
                 this.setFieldVisible('regionalregistryflag', false);
             }

             if (show_registrynumber) {
                 this.setFieldVisible('registrynumber', true);
             }
             else {
                 this.setFieldVisible('registrynumber', false);
             }

             if (show_registrydate) {
                 this.setFieldVisible('registrydate', true);
             }
             else {
                 this.setFieldVisible('registrydate', false);
             }
         },

         checkRegistry() {
             let field = this.findField('registersubscriptionflag');

             this.showRegistryFields(field.value);
         },

         showAcsiFields(value) {
             let field;

             if (typeof value !== "undefined" && !value) {
                 this.setFieldVisible('epsflag', true);
                 this.setFieldVisible('fsnflag', true);
                 this.setFieldVisible('dsaflag', true);
                 this.setFieldVisible('otherflag', true);

                 field = this.findField('epsflag');
                 if (typeof field.value !== "undefined" && field.value) {
                     this.setFieldVisible('epsdescription', true);
                 }
                 else {
                     this.setFieldVisible('epsdescription', false);
                 }

                 field = this.findField('fsnflag');
                 if (typeof field.value !== "undefined" && field.value) {
                     this.setFieldVisible('fsndescription', true);
                 }
                 else {
                     this.setFieldVisible('fsndescription', false);
                 }

                 field = this.findField('dsaflag');
                 if (typeof field.value !== "undefined" && field.value) {
                     this.setFieldVisible('dsadescription', true);
                 }
                 else {
                     this.setFieldVisible('dsadescription', false);
                 }

                 field = this.findField('otherflag');
                 if (typeof field.value !== "undefined" && field.value) {
                     this.setFieldVisible('otherdescription', true);
                 }
                 else {
                     this.setFieldVisible('otherdescription', false);
                 }
             }
             else {
                 this.setFieldVisible('epsflag', false);
                 this.setFieldVisible('fsnflag', false);
                 this.setFieldVisible('dsaflag', false);
                 this.setFieldVisible('otherflag', false);

                 this.setFieldVisible('epsdescription', false);
                 this.setFieldVisible('fsndescription', false);
                 this.setFieldVisible('dsadescription', false);
                 this.setFieldVisible('otherdescription', false);
             }
         },

         checkAcsiOnly() {
             let field = this.findField('acsionly');

             this.showAcsiFields(field.value);
         },

         resetFields() {
             for(let i in this.originalFields) {
                 let field = this.findField(this.originalFields[i].name);

                 this.setFieldAttribute(this.originalFields[i].name, 'is_visible_for_store', this.originalFields[i].is_visible_for_store);
                 this.setFieldAttribute(this.originalFields[i].name, 'is_visible_for_update', this.originalFields[i].is_visible_for_update);

                 this.setFieldAttribute(this.originalFields[i].name, 'nullable', this.originalFields[i].nullable);
             }
         },

         loadOptions(type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/sportingclub/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(fieldName, 'filter_options', options);
                           }
                       });
         },

         showPostalAddresses() {
             if (['Superadmin', 'Segreteria Nazionale'].includes(this.$store.getters.currentUser.usertype)) {
                 this.setFieldVisible('postalmessage', true);
                 this.setFieldVisible('postaladdress', true);
                 this.setFieldVisible('postalidregion', true);
                 this.setFieldVisible('postalidprovstate', true);
                 this.setFieldVisible('postalidcity', true);
                 this.setFieldVisible('postalstreetaddress', true);
                 this.setFieldVisible('postalstreetnumber', true);
                 this.setFieldVisible('postalzipcode', true);
             }
             else {
                 this.setFieldVisible('postalmessage', false);
                 this.setFieldVisible('postaladdress', false);
                 this.setFieldVisible('postalidregion', false);
                 this.setFieldVisible('postalidprovstate', false);
                 this.setFieldVisible('postalidcity', false);
                 this.setFieldVisible('postalstreetaddress', false);
                 this.setFieldVisible('postalstreetnumber', false);
                 this.setFieldVisible('postalzipcode', false);
             }
         },

         hidePostalAddresses() {
             this.setFieldVisible('postalmessage', false);
             this.setFieldVisible('postaladdress', false);
             this.setFieldVisible('postaladdress', false);
             this.setFieldVisible('postalidregion', false);
             this.setFieldVisible('postalidprovstate', false);
             this.setFieldVisible('postalidcity', false);
             this.setFieldVisible('postalstreetaddress', false);
             this.setFieldVisible('postalstreetnumber', false);
             this.setFieldVisible('postalzipcode', false);
         },

         showTaxAddresses() {
             if (['Superadmin', 'Segreteria Nazionale'].includes(this.$store.getters.currentUser.usertype)) {
                 this.setFieldVisible('taxmessage', true);
                 this.setFieldVisible('taxaddress', true);
                 this.setFieldVisible('taxidregion', true);
                 this.setFieldVisible('taxidprovstate', true);
                 this.setFieldVisible('taxidcity', true);
                 this.setFieldVisible('taxstreetaddress', true);
                 this.setFieldVisible('taxstreetnumber', true);
                 this.setFieldVisible('taxzipcode', true);
             }
             else {
                 this.setFieldVisible('taxmessage', false);
                 this.setFieldVisible('taxaddress', false);
                 this.setFieldVisible('taxidregion', false);
                 this.setFieldVisible('taxidprovstate', false);
                 this.setFieldVisible('taxidcity', false);
                 this.setFieldVisible('taxstreetaddress', false);
                 this.setFieldVisible('taxstreetnumber', false);
                 this.setFieldVisible('taxzipcode', false);
             }
         },

         hideTaxAddresses() {
             this.setFieldVisible('taxmessage', false);
             this.setFieldVisible('taxaddress', false);
             this.setFieldVisible('taxidregion', false);
             this.setFieldVisible('taxidprovstate', false);
             this.setFieldVisible('taxidcity', false);
             this.setFieldVisible('taxstreetaddress', false);
             this.setFieldVisible('taxstreetnumber', false);
             this.setFieldVisible('taxzipcode', false);
         },
 },
 };
</script>
